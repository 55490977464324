import React from 'react';

import './home-page.styles.scss';

const HomePage = () => {
    return (
        <div className='content'>
            Coming Soon... <br/>
            Website currently under construction
        </div>
    )
}

export default HomePage;